import { Controller } from "@hotwired/stimulus"
import sanitizeHtml from 'sanitize-html'

export default class extends Controller {
  static targets = ["body", "template", "fallback", "attribute", "plainText", "trixEditor", "plainTextInput", "trixEditorInput"]

  connect() {
    const that = this;
    this.lastCursorPosition = null;
    this.addTrixToolbar = this.addTrixToolbar.bind(this)
    this.addTrixToolbars = this.addTrixToolbars.bind(this)
    this.trixEditor = this.element.querySelector("trix-editor")
    this.trixInput = this.trixEditorInputTarget
    // this.plainTextInput = this.plainTextInputTarget

    this.element.addEventListener("trix-initialize", function(event) {
      const trixEditor = event.target;
      that.editor = trixEditor.editor;

      trixEditor.addEventListener("paste", function(ev) {
        const clipboardData = ev.clipboardData || window.clipboardData;
        if (!clipboardData) return;

        const html = clipboardData.getData("text/html");
        const plainText = clipboardData.getData("text/plain");
        if (html) {
          // Prevent default paste behavior
          ev.preventDefault();
          ev.stopPropagation();
          // Undo the paste
          trixEditor.editor.undo();
          // Insert the HTML as-is
          trixEditor.editor.insertHTML(html);
        } else if (plainText) {
          // If plain text is pasted, wrap it in a <pre> tag for code
          ev.preventDefault();
          ev.stopPropagation();
          // Undo the paste
          trixEditor.editor.undo();
          // Insert the HTML as-is
          trixEditor.editor.insertHTML(sanitizeHtml(plainText));
        }
      });
    }, { once: true });

    this.element.addEventListener('trix-initialize', this.addTrixToolbars, { once: true });
    // this.templateTarget.addEventListener('change', function(){
    //   turboFetch(that.templateTarget.dataset.templateUrl.replace('__ID__', this.value), function(){
    //   })
    // })

    // if (this.hasPlainTextInput) {
    //   this.disablePlainTextEditor()
    // }
  }

  addTrixToolbars(event) {
    const that = this;
    const editor = event.target.editor;
    let toolbar = this.element.querySelector('.trix-button-group-spacer')
    toolbar.classList.add('ms-4')
    this.addAttributeButton(toolbar, 'insert_attribute')

    // Event listener for cursor change
    editor.element.addEventListener('trix-selection-change', function(event) {
      that.lastCursorPosition = editor.getSelectedRange();
    });

    // Event listener for custom button
    toolbar.addEventListener('click', function(event) {
      if (event.target.getAttribute('data-trix-attribute') === 'keytext') {
        const keyText = event.target.getAttribute('data-trix-value');
        if (that.lastCursorPosition !== null) {
          editor.setSelectedRange(that.lastCursorPosition);
          editor.insertString(keyText);
        } else {
          const currentPosition = editor.getSelectedRange();
          editor.insertString(keyText, currentPosition[0]);
        }
      }

      if (event.target.getAttribute('data-trix-attribute') === 'insertattribute') {
        that.editor = editor;
        that.attributeModal.show();
      }
    });
  }

  initModalInsertAttribute(toolbar) {
    // set the modal menu element
    const id = this.element.dataset.id || toolbar.id;
    const modalId = `modal-insert-attribute-${id}`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.attributeModal = new Modal($targetEl, options, instanceOptions);
  }

  insertAttribute(event) {
    if (this.editor) {
      const attribute = this.attributeTarget.value;
      const keyText = `[${attribute}]`;

      if (this.lastCursorPosition !== null) {
        this.editor.setSelectedRange(this.lastCursorPosition);
        this.editor.insertString(keyText);
      } else {
        const currentPosition = this.editor.getSelectedRange();
        this.editor.insertString(keyText, currentPosition[0]);
      }

      if (this.fallbackTarget.value) {
        const inputId = `fallbacks_${attribute}_${this.element.dataset.id}`;
        const value = this.fallbackTarget.value;
        let hiddenInput = this.element.querySelector(`#${inputId}`);
        if (!hiddenInput) { hiddenInput = document.createElement('input'); }
        hiddenInput.type = 'hidden';
        hiddenInput.name = `campaign[metadata][fallbacks][${attribute}]`;
        hiddenInput.value = value;
        hiddenInput.id = inputId;
        this.element.appendChild(hiddenInput);
      }
    }

    this.attributeModal.hide()
  }

  closeAttributeModal() {
    if (this.attributeModal) {
      this.attributeModal.hide()
    }
  }

  addAttributeButton(toolbar, button) {
    const id = this.element.dataset.id || toolbar.id;
    const modalId = `modal-insert-attribute-${id}`;

    toolbar.insertAdjacentHTML(
      'beforeend',
      `<button type="button" class="trix-button-group-button gap-1 text-gray-900 dark:text-gray-400 cursor-pointer hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded py-0.5 px-2 inline-flex items-center justify-center bg-white border-gray-200 border" data-trix-attribute="insertattribute" data-trix-value="[${button}]" title="${button}">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M43.18,128a29.78,29.78,0,0,1,8,10.26c4.8,9.9,4.8,22,4.8,33.74,0,24.31,1,36,24,36a8,8,0,0,1,0,16c-17.48,0-29.32-6.14-35.2-18.26-4.8-9.9-4.8-22-4.8-33.74,0-24.31-1-36-24-36a8,8,0,0,1,0-16c23,0,24-11.69,24-36,0-11.72,0-23.84,4.8-33.74C50.68,38.14,62.52,32,80,32a8,8,0,0,1,0,16C57,48,56,59.69,56,84c0,11.72,0,23.84-4.8,33.74A29.78,29.78,0,0,1,43.18,128ZM240,120c-23,0-24-11.69-24-36,0-11.72,0-23.84-4.8-33.74C205.32,38.14,193.48,32,176,32a8,8,0,0,0,0,16c23,0,24,11.69,24,36,0,11.72,0,23.84,4.8,33.74a29.78,29.78,0,0,0,8,10.26,29.78,29.78,0,0,0-8,10.26c-4.8,9.9-4.8,22-4.8,33.74,0,24.31-1,36-24,36a8,8,0,0,0,0,16c17.48,0,29.32-6.14,35.2-18.26,4.8-9.9,4.8-22,4.8-33.74,0-24.31,1-36,24-36a8,8,0,0,0,0-16Z"></path></svg>
        Insert attribute
      </button>`
    );
    toolbar.insertAdjacentHTML(
      'beforeend',
      `
      <div id="${modalId}" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow-sm dark:bg-gray-700">
            <div class="flex items-center justify-between p-3 border-b rounded-t dark:border-gray-600 border-gray-200">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                Insert Attribute
              </h3>
              <button data-action="click->trix-editor#closeAttributeModal" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div class="p-4 md:p-5">
              <div class="grid gap-4 mb-4 grid-cols-2">
                <div class="col-span-2">
                  <label for="attribute" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Attribute</label>
                  <select id="attribute" required data-trix-editor-target="attribute" class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
                    <option value="first_name" selected="">First name</option>
                    <option value="last_name">Last name</option>
                    <option value="company">Company</option>
                    <option value="phome">Phone</option>
                    <option value="unsub">Unsubcription</option>
                  </select>
                </div>
                <div class="col-span-2">
                  <label for="fallback" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Fallback</label>
                  <input type="text" data-trix-editor-target="fallback" name="fallback" id="fallback" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"/>
                  <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">Value if the attribute is empty</p>
                </div>
              </div>
              <div class="flex items-center w-full justify-center border-gray-200 rounded-b dark:border-gray-600">
                <button data-action="click->trix-editor#insertAttribute" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Insert</button>
                <button data-action="click->trix-editor#closeAttributeModal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      `
    );
    this.initModalInsertAttribute(toolbar)
  }

  addTrixToolbar(toolbar, button) {
    toolbar.insertAdjacentHTML(
      'beforeend',
      `<span class="trix-button-group-button text-gray-900 dark:text-gray-400 mx-1.5 text-sm hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded py-1 px-2 inline-flex items-center justify-center bg-white border-gray-200 border" data-trix-attribute="keytext" data-trix-value="[${button}]" title="${button}">[${button}]</span>`
    );
  }

  disablePlainTextEditor() {
    this.plainTextTarget.setAttribute('disabled', true)
    this.plainTextTarget.classList.add('disabled')
    this.plainTextInput.style.disabled = true
    this.plainTextInput.setAttribute('disabled', true)
  }

  enablePlainTextEditor() {
    this.plainTextTarget.removeAttribute('disabled')
    this.plainTextTarget.classList.remove('disabled')
    this.plainTextInput.style.disabled = false
    this.plainTextInput.removeAttribute('disabled')
  }

  disableTrixEditor() {
    this.trixEditor.setAttribute('contenteditable', 'false')
    this.trixEditor.classList.add('disabled')
    this.trixInput.style.disabled = true
  }

  enableTrixEditor() {
    this.trixEditor.setAttribute('contenteditable', 'true')
    this.trixEditor.classList.remove('disabled')
    this.trixInput.style.disabled = false
  }

  showPlainText() {
    // Synchronize the content from Trix to the plain text area
    this.plainTextInput.innerHTML = this.trixInput.value
    // Enable the plain text area and disable the Trix editor
    this.enablePlainTextEditor()
    this.disableTrixEditor()
  }

  showTrix() {
    // Synchronize the content from the plain text area to Trix
    this.trixInput.value = this.plainTextInput.value
    this.trixEditor.editor.loadHTML(this.plainTextInput.value)
    // Enable the Trix editor and disable the plain text area
    this.enableTrixEditor()
    this.disablePlainTextEditor()
  }
}
