import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="search---saved-profile"
export default class extends Controller {
  static targets = ["modal", "overlay", "total", "credit", "progress"];

  connect() {
    try {
      this.close = this.close.bind(this);
      this.submitEnd = this.submitEnd.bind(this);
      this.add = this.add.bind(this);
      this.initModal = this.initModal.bind(this);
      this.modal = this.initModal();
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  initModal() {
    // set the modal menu element
    const $targetEl = document.getElementById('limit-credit-modal');

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'limit-credit-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
        document.querySelectorAll('.limit-credit-modal-backdrop').forEach((element) => {
          element.remove();
        });
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: 'limit-credit-modal',
      override: true
    };

    return new Modal($targetEl, options, instanceOptions);
  }

  show(event) {
    // Add error handling when implementing
  }

  close(event) {
    try {
      this.modal.hide()
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  addSingle(event) {
    try {
      const availableCredits = document.getElementById('available_credits').value || 0;
      const isVerified = document.getElementById('is_verified').value || false;
      if (availableCredits <= 0) {
        document.getElementById('outOfCredit').classList.remove('hidden')
        document.getElementById('isVerified').classList.add('hidden')
        document.getElementById('limitCredit').classList.add('hidden')
        event.preventDefault();
        this.modal.show();
      } else if (isVerified == true || isVerified == "true") {
        this.toggleSubmitButton(event.target);
        event.target.closest('form').requestSubmit();
      } else {
        document.getElementById('outOfCredit').classList.add('hidden')
        document.getElementById('isVerified').classList.remove('hidden')
        document.getElementById('limitCredit').classList.add('hidden')
        event.preventDefault();
        this.modal.show();
      }
    } catch (error) {
      console.error("Error in add:", error);
      this.toggleSubmitButton(event.target);
    }
  }

  add(event) {
    try {
      const availableCredits = document.getElementById('available_credits').value || 0;
      const selectedValues = this.selectedCheckboxes();
      const totalSelects = selectedValues.length;

      if (availableCredits < totalSelects && !event.target.dataset.force) {
        if (availableCredits <= 0) {
          document.getElementById('outOfCredit').classList.remove('hidden')
          document.getElementById('limitCredit').classList.add('hidden')
          document.getElementById('isVerified').classList.add('hidden')
        } else {
          document.getElementById('limitCredit').classList.remove('hidden')
          document.getElementById('outOfCredit').classList.add('hidden')
          document.getElementById('isVerified').classList.add('hidden')
          this.totalTarget.textContent = availableCredits;
          this.creditTarget.textContent = totalSelects;
          this.progressTarget.style.width = `${(availableCredits/totalSelects) * 100}%`
        }
        this.modal.show();
        event.preventDefault();
        return
      }

      this.toggleSubmitButton(event.target);
      const form = event.target.closest('form');
      const searchForm = document.getElementById('searchForm');
      const action = form?.getAttribute('action');
      const method = form?.getAttribute('method') || 'post';
      const modal = event.target.closest('#bulk-add-modal');

      if (!form || !action) {
        console.error("Form or action attribute is missing.");
        this.toggleSubmitButton(event.target);
        return;
      }

      let params = new FormData(form);

      // Copy parameters from search form, excluding 'search_terms'
      if (searchForm) {
        const filteredParams = new FormData(searchForm);
        for (let [key, value] of filteredParams.entries()) {
          if (key !== 'search_terms') {
            params.append(key, value);
          }
        }
      }

      // Add selected checkboxes to params
      selectedValues.forEach(element => {
        params.append(element.getAttribute('name'), element.value);
        element.classList.remove('selected_ids');
      });

      // Add existing checkboxes to params
      const existedInputs = document.getElementsByClassName('existed_ids');
      Array.from(existedInputs).forEach(element => {
        params.append(element.getAttribute('name'), element.value);
      });

      params.append('commit', event.target.dataset.commit);

      fetch(action, {
        method: method,
        headers: { Accept: "text/vnd.turbo-stream.html" },
        body: params
      })
        .then(r => r.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
          this.toggleSubmitButton(event.target);
          this.clearChecked(selectedValues);
          this.dispatch("change", { detail: { field_name: 'selected_ids', field_value: selectedValues } });

          if (modal) {
            const closeButton = modal.querySelector('[data-modal-hide]');
            if (closeButton) closeButton.click();
          }

          if (event.target.dataset.force) {
            this.modal.hide();
          }

          return true;
        })
        .catch(error => {
          console.error("Error in add fetch:", error);
          this.toggleSubmitButton(event.target);
        });
    } catch (error) {
      console.error("Error in add:", error);
      this.toggleSubmitButton(event.target);
    }
  }

  toggleSubmitButton(button) {
    try {
      const originalContent = button.innerHTML;
      const loadingContent = button.getAttribute('data-turbo-submits-with');

      if (loadingContent) {
        button.setAttribute('data-turbo-submits-with', originalContent);
        button.innerHTML = loadingContent;
      }

      button.disabled = !button.disabled; // Optionally disable the button
    } catch (error) {
      console.error("Error in toggleSubmitButton:", error);
    }
  }

  showModal(modalId) {
    // Add error handling when implementing
  }

  hideModal(modalId) {
    // Add error handling when implementing
  }

  submitEnd(event) {
    try {
      const response = event.detail.fetchResponse?.response;

      if (response && response.status === 200) {
        this.clearForm();
        const closeButton = this.element.querySelector('[data-modal-hide]');
        if (closeButton) closeButton.click();
      }

      this.dispatch("change", { detail: event.detail });
    } catch (error) {
      console.error("Error in submitEnd:", error);
    }
  }

  selectedCheckboxes() {
    try {
      const checkboxes = document.getElementsByClassName('selected_ids');
      return Array.from(checkboxes).filter(checkbox => checkbox.checked);
    } catch (error) {
      console.error("Error in selectedCheckboxes:", error);
      return [];
    }
  }

  clearChecked(selectedValues) {
    try {
      const totalCheck = document.getElementById('select-all-checkbox');
      if (totalCheck) {
        totalCheck.click();
      } else if (selectedValues.length > 0) {
        selectedValues.forEach(element => {
          element.checked = false;
        });
      }

      const addForm = document.getElementById('addForm');
      if (addForm) {
        addForm.classList.remove('is-selected');
      }
    } catch (error) {
      console.error("Error in clearChecked:", error);
    }
  }

  clearForm() {
    try {
      const elements = this.element.querySelectorAll('select, input');
      elements.forEach(element => {
        if (element.type === 'checkbox' || element.type === 'radio') {
          element.checked = false; // Uncheck checkboxes and radio buttons
        } else {
          element.value = ''; // Clear other input values
        }
      });
    } catch (error) {
      console.error("Error in clearForm:", error);
    }
  }
}
