import { add, Controller } from "@hotwired/stimulus"
import { Editor , Extension, Node, mergeAttributes} from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Focus from "@tiptap/extension-focus";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import FloatingMenu from "@tiptap/extension-floating-menu";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Bold from '@tiptap/extension-bold'
import Document from '@tiptap/extension-document'
import { FontFamily } from '@tiptap/extension-font-family'
import Paragraph from '@tiptap/extension-paragraph'
import FontSize from '@tiptap/extension-font-size'
import Heading from "@tiptap/extension-heading";
import ImageResize from 'tiptap-extension-resize-image';
import lodash from 'lodash'

const PreserveAllAttributes = Extension.create({
  name: "preserveAllAttributes",

  addGlobalAttributes() {
    return [
      {
        // Apply to block and inline elements (not text nodes)
        types: ["paragraph", "heading", "div", "span", "strong", "p"],
        attributes: {
          preservedAttrs: {
            default: {},
            parseHTML: (element) => {
              const attributes = {};
              Array.from(element.attributes).forEach((attr) => {
                if (!attr.name.startsWith("data-node-")) {
                  attributes[attr.name] = attr.value;
                }
              });
              return attributes;
            },
            renderHTML: (attributes) => {
              return attributes.preservedAttrs
                ? Object.fromEntries(Object.entries(attributes.preservedAttrs))
                : {};
            },
          },
        },
      },
    ];
  },

  addNodeView() {
    return ({ node, HTMLAttributes }) => {
      const dom = document.createElement(node.type.name);

      // Set preserved attributes
      Object.entries(HTMLAttributes.preservedAttrs || {}).forEach(
        ([key, value]) => {
          dom.setAttribute(key, value);
        }
      );

      return dom;
    };
  },
});

export const Variable = Node.create({
  name: "variable",
  group: "inline",
  inline: true,
  atom: true,

  addAttributes() {
    return {
      name: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-name"),
        renderHTML: (attributes) => {
          return { "data-name": attributes.name };
        },
      },
    };
  },

  parseHTML() {
    return [{ tag: "span[data-variable]" }];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        "data-variable": node.attrs.name,
        contenteditable: "false",
        style: "background: yellow; padding: 2px 4px; border-radius: 4px;",
      }),
      `{${node.attrs.name}}`,
    ];
  },
});

StarterKit.configure({
  HTMLAttributes: { allow: 'style' },
  preserveWhitespace: true,
});

// Create a custom extension to allow specific attributes
const CustomTable = Table.extend({
  addAttributes() {
    return {
      cellpadding: { default: null },
      cellspacing: { default: null },
      border: { default: null },
      width: { default: null },
      style: { default: null },
    };
  },
  parseHTML() {
    return [
      {
        tag: "table",
        getAttrs: (node) => {
          if (typeof node === "string") return {};
          const element = node;
          return {
            cellpadding: element.getAttribute("cellpadding"),
            cellspacing: element.getAttribute("cellspacing"),
            border: element.getAttribute("border"),
            width: element.getAttribute("width"),
            style: element.getAttribute("style"),
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["table", HTMLAttributes, 0];
  },
});

// Create a custom extension to allow specific attributes
const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      cellpadding: { default: null },
      cellspacing: { default: null },
      border: { default: null },
      width: { default: null },
      style: { default: null },
    };
  },
  parseHTML() {
    return [
      {
        tag: "tr",
        getAttrs: (node) => {
          if (typeof node === "string") return {};
          const element = node;
          return {
            cellpadding: element.getAttribute("cellpadding"),
            cellspacing: element.getAttribute("cellspacing"),
            border: element.getAttribute("border"),
            width: element.getAttribute("width"),
            style: element.getAttribute("style"),
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["tr", HTMLAttributes, 0];
  },
});

// Create a custom extension to allow specific attributes
const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      cellpadding: { default: null },
      cellspacing: { default: null },
      border: { default: null },
      width: { default: null },
      style: { default: null },
    };
  },
  parseHTML() {
    return [
      {
        tag: "td",
        getAttrs: (node) => {
          if (typeof node === "string") return {};
          const element = node;
          return {
            cellpadding: element.getAttribute("cellpadding"),
            cellspacing: element.getAttribute("cellspacing"),
            border: element.getAttribute("border"),
            width: element.getAttribute("width"),
            style: element.getAttribute("style"),
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["td", HTMLAttributes, 0];
  },
});

// Create a custom extension to allow specific attributes
const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      cellpadding: { default: null },
      cellspacing: { default: null },
      border: { default: null },
      width: { default: null },
      style: { default: null },
    };
  },
  parseHTML() {
    return [
      {
        tag: "td",
        getAttrs: (node) => {
          if (typeof node === "string") return {};
          const element = node;
          return {
            cellpadding: element.getAttribute("cellpadding"),
            cellspacing: element.getAttribute("cellspacing"),
            border: element.getAttribute("border"),
            width: element.getAttribute("width"),
            style: element.getAttribute("style"),
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["td", HTMLAttributes, 0];
  },
});

const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: { default: null },
      target: { default: null },
      rel: { default: null },
    };
  },
});

const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: { default: null },
      target: { default: null },
      rel: { default: null },
      width: { default: null },
      height: { default: null },
    };
  },
  parseHTML() {
    return [
      {
        tag: "img",
        getAttrs: (node) => {
          if (typeof node === "string") return {};
          const element = node;
          return {
            src: element.getAttribute("src"),
            alt: element.getAttribute("alt"),
            style: element.getAttribute("style"),
            target: element.getAttribute("target"),
            rel: element.getAttribute("rel"),
            width: element.getAttribute("width"),     // ✅ Preserve width
            height: element.getAttribute("height"),   // ✅ Preserve height
          };
        },
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ["img", HTMLAttributes];  // ✅ Render width & height
  },
});

export default class extends Controller {
  static targets = ["editor", "template", "input"];

  inputTargetConnected(input) {
    input.addEventListener('input', this.inputChange.bind(this));
    this.inputChange({ target: input });
  }

  inputTargetDisconnected(input) {
    input.removeEventListener('input', this.inputChange.bind(this))
  }

  inputChange(event) {
    const name = event.target.name;
    const match = name.match(/\[([^\]]+)]$/);
    const attribute = match ? match[1] : null;
    if (attribute && event.target.value) {
      this.dataProfile[attribute] = event.target.value;
      console.log('inputChange', this.dataProfile);
      this.reloadEditorContent();
    }
  }

  initialize() {
    this.dataProfile = lodash.merge((this.element.dataset.profile || {}), {
      first_name: "John",
      last_name: "Smith",
      job_title: 'Marketer',
      title: 'He / Him',
      website: 'www.aeroleads.com',
      department: 'Marketing',
      company: 'Aeroleads',
      address: 'hourse number, city, state',
      office_phone_number: '111 222 3333',
      mobile_phone_number: '111 222 3333',
      email: "john@example.com"
    });
  }

  connect() {
    // Define the template with placeholders
    const template = this.templateTarget.innerHTML;
    this.createBubbleMenu = this.createBubbleMenu.bind(this);
    this.updateBubbleMenu = this.updateBubbleMenu.bind(this);
    this.reloadEditorContent = this.reloadEditorContent.bind(this);
    this.bubbleMenu = null;

    // Initialize Tiptap Editor
    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit.configure({
          codeBlock: false,
        }),
        Variable,
        PreserveAllAttributes,
        TextStyle,
        Color,
        Document,
        Paragraph,
        Bold,
        FontFamily.configure({
          types: ["textStyle"],
        }),
        FontSize.configure({
          types: ["textStyle"],
        }),
        Heading.configure({
          levels: [1, 2, 3, 4, 5, 6],
        }),
        ImageResize,
        CustomImage,
        CustomLink.configure({ openOnClick: false }),
        Placeholder.configure({
          placeholder: ({ node }) => {
            if (node.attrs["data-placeholder"] === "first-name") return "First Name";
            if (node.attrs["data-placeholder"] === "last-name") return "Last Name";
            return "Type here...";
          },
        }),
        Focus.configure({
          className: "has-focus",
        }),
        // Floating Menu: Appears for each block
        FloatingMenu.configure({
          pluginKey: "floatingMenu",
          shouldShow: ({ state }) => {
            const { $anchor } = state.selection;
            const isEmptyTextBlock =
              $anchor.parent.isTextblock && !$anchor.parent.textContent;
            return isEmptyTextBlock;
          },
        }),
        // Bubble Menu: Appears for selected text
        BubbleMenu.configure({
          pluginKey: "bubbleMenu",
          element: this.createBubbleMenu()
        }),
        CustomTable,
        CustomTableRow,
        CustomTableCell,
        CustomTableHeader,
      ],
      content: template,
      onFocus: () => this.updateBubbleMenu(),          // Trigger on focus
      onSelectionUpdate: () => this.updateBubbleMenu(), // Trigger on selection change
      onUpdate: () => this.updateBubbleMenu(),          // Trigger on content update
    });

    console.log('reloadEditorContent', this.dataProfile)
    const values = this.dataProfile;
    this.editor.commands.setContent(
      this.editor.getHTML().replace(/\{(\w+)\}/g, (_, name) => values[name] || `{${name}}`)
    );
  }

  reloadEditorContent() {
    if (this.editor) {
      console.log('reloadEditorContent')
      const values = this.dataProfile;
      this.editor.commands.setContent(this.templateTarget.innerHTML);
      this.editor.commands.setContent(
        this.editor.getHTML().replace(/\{(\w+)\}/g, (_, name) => values[name] || `{${name}}`)
      );
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  updateBubbleMenu() {
    // Check if the focused element is an image
    const isImageSelected = this.editor?.isActive("resizeImage") || this.editor?.isActive("image");
    if (isImageSelected) {
      if (this.bubbleMenu) { this.bubbleMenu.classList.add('hidden') }
    } else {
      if (this.bubbleMenu) {
        this.bubbleMenu.classList.remove('hidden')
      } else {
        this.createBubbleMenu();
      }
    }
  }

  // Create a floating toolbar for selected text
  createBubbleMenu() {
    let bubbleMenu = document.getElementById('bubble-menu');
    if (!bubbleMenu) {
      bubbleMenu = document.createElement("div");
      bubbleMenu.id = "bubble-menu";
      bubbleMenu.className = "bubble-menu inline-flex items-center rounded-md shadow p-1.5 bg-white";
    }

    this.bubbleMenu = bubbleMenu;
    // Check if the focused element is an image
    const isImageSelected = this.editor?.isActive("resizeImage") || this.editor?.isActive("image");

    console.log('createBubbleMenu', isImageSelected);

    if (isImageSelected) {
      // Get current image attributes
      const imageAttrs = this.editor.getAttributes("resizeImage");
      const currentWidth = imageAttrs.width || "";
      const currentHeight = imageAttrs.height || "";

      // Image Width Input
      const widthInput = document.createElement("input");
      widthInput.type = "number";
      widthInput.placeholder = "Width";
      widthInput.value = currentWidth;
      widthInput.className = "p-1 me-2 text-sm border border-gray-300 rounded";
      widthInput.onchange = () => {
        const width = parseInt(widthInput.value, 10) || null;
        if (width) this.editor.chain().focus().updateAttributes("resizeImage", { width: `${width}px` }).run();
      };
      bubbleMenu.appendChild(widthInput);

      // Image Height Input
      const heightInput = document.createElement("input");
      heightInput.type = "number";
      heightInput.placeholder = "Height";
      heightInput.value = currentHeight;
      heightInput.className = "p-1 me-2 text-sm border border-gray-300 rounded";
      heightInput.onchange = () => {
        const height = parseInt(heightInput.value, 10) || null;
        if (height) this.editor.chain().focus().updateAttributes("resizeImage", { height: `${height}px` }).run();
      };
      bubbleMenu.appendChild(heightInput);

      // Maintain Aspect Ratio Checkbox
      const aspectRatioCheckbox = document.createElement("input");
      aspectRatioCheckbox.type = "checkbox";
      aspectRatioCheckbox.id = "aspect-ratio-checkbox";
      aspectRatioCheckbox.className = "me-2";
      const aspectRatioLabel = document.createElement("label");
      aspectRatioLabel.htmlFor = "aspect-ratio-checkbox";
      aspectRatioLabel.textContent = "Lock Ratio";
      aspectRatioLabel.className = "text-sm me-3";
      bubbleMenu.appendChild(aspectRatioCheckbox);
      bubbleMenu.appendChild(aspectRatioLabel);

      // Sync height with width if aspect ratio is locked
      widthInput.oninput = () => {
        if (aspectRatioCheckbox.checked) {
          const aspectRatio = imageAttrs.width / imageAttrs.height;
          heightInput.value = Math.round(parseInt(widthInput.value, 10) / aspectRatio).toString();
        }
      };
      heightInput.oninput = () => {
        if (aspectRatioCheckbox.checked) {
          const aspectRatio = imageAttrs.height / imageAttrs.width;
          widthInput.value = Math.round(parseInt(heightInput.value, 10) / aspectRatio).toString();
        }
      };

      // Remove Image Button
      const removeImageButton = document.createElement("button");
      removeImageButton.textContent = "Remove Image";
      removeImageButton.className = "px-2 py-1 text-sm text-white bg-red-500 rounded hover:bg-red-600";
      removeImageButton.onclick = () => {
        this.editor.chain().focus().deleteSelection().run();
      };
      bubbleMenu.appendChild(removeImageButton);
    } else {
      // Bold Button
      const boldButton = document.createElement("button");
      boldButton.className = "px-2 py-1 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";
      boldButton.textContent = "B";
      boldButton.onclick = () => this.editor.chain().focus().toggleBold().run();
      bubbleMenu.appendChild(boldButton);

      // Italic Button
      const italicButton = document.createElement("button");
      italicButton.textContent = "I";
      italicButton.className="px-2 py-1 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";
      italicButton.onclick = () => this.editor.chain().focus().toggleItalic().run();
      bubbleMenu.appendChild(italicButton);

      // Link Button
      const linkButton = document.createElement("button");
      linkButton.className = "px-2 py-1 me-3 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";
      linkButton.textContent = "🔗";
      linkButton.onclick = () => {
        const url = prompt("Enter URL:", this.editor.getAttributes("link").href || "https://");
        if (url) {
          this.editor.chain().focus().setLink({ href: url }).run();
        } else {
          this.editor.chain().focus().unsetLink().run();
        }
      };
      bubbleMenu.appendChild(linkButton);

      // Font Size Dropdown
      const fontSizeSelect = document.createElement("select");
      fontSizeSelect.className = "px-2 py-1 me-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
      ["12px", "14px", "16px", "18px", "20px", "24px"].forEach((size) => {
        const option = document.createElement("option");
        option.value = size;
        option.textContent = size;
        fontSizeSelect.appendChild(option);
      });
      fontSizeSelect.onchange = () => {
        this.editor.chain().focus().setFontSize(fontSizeSelect.value).run();
      };
      bubbleMenu.appendChild(fontSizeSelect);

      // Font Color Picker
      const colorInput = document.createElement("input");
      colorInput.id = "bubble-color-input"
      let currentColor = "#000000";
      if (this.editor?.getAttributes("textStyle")) {
        currentColor = this.editor?.getAttributes("textStyle").color;
      }
      colorInput.value = currentColor;  // Set the default color
      colorInput.className = "rounded-lg me-3 border border-gray-300";
      colorInput.type = "color";
      colorInput.onchange = () => {
        this.editor.chain().focus().setColor(colorInput.value).run();
      };
      bubbleMenu.appendChild(colorInput);

      // Heading Dropdown
      const headingSelect = document.createElement("select");
      headingSelect.className = "px-2 py-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
      [["Normal", ""], ["H1", "h1"], ["H2", "h2"], ["H3", "h3"]].forEach(([label, level]) => {
        const option = document.createElement("option");
        option.value = level;
        option.textContent = label;
        headingSelect.appendChild(option);
      });
      headingSelect.onchange = () => {
        const level = headingSelect.value;
        if (level) {
          this.editor.chain().focus().toggleHeading({ level: parseInt(level.replace("h", "")) }).run();
        } else {
          this.editor.chain().focus().setParagraph().run();
        }
      };
      bubbleMenu.appendChild(headingSelect);
    }

    // Append the bubble menu to the body
    document.body.appendChild(bubbleMenu);
    return bubbleMenu;
  }

  save() {
    // Extract HTML content from Tiptap
    const html = this.editor.getHTML();

    // Send the content to your Rails backend (AJAX request or form submission)
    fetch("/signatures", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
      },
      body: JSON.stringify({ signature: { content: html } }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Signature saved successfully!");
      })
      .catch((error) => console.error("Error saving signature:", error));
  }
}