import { Controller } from "@hotwired/stimulus"
import moment from 'moment-timezone';

export default class extends Controller {
  static targets = ["toggle", "starttime", "endtime"]

  toggleTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.toggleChange.bind(this))

    this.toggleChange({ target: checkbox });
  }

  toggleTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.toggleChange.bind(this))
  }

  connect() {
    const currentTimeZone = moment.tz.guess();
    const $timeZoneEl = document.getElementById('timezones');

    if ($timeZoneEl) {
      $timeZoneEl.value = currentTimeZone;
    }
  }

  toggleChange(event) {
    const className = event.target.dataset.groupName || event.target.value

    if (event.target.checked) {
      this.element.classList.add(className)
    } else {
      this.element.classList.remove(className)
    }
  }

  timeChange(event) {
    const groupName = event.target.dataset.groupName;

    if (groupName == "starttime") {
      this.starttimeTargets.forEach(element => element.value = event.target.value);
    }

    if (groupName == "endtime") {
      this.endtimeTargets.forEach(element => element.value = event.target.value);
    }
  }
}
