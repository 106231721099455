import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['annual', 'monthly', 'mode']

  connect() {
  }

  modeTargetConnected(radio) {
    radio.addEventListener('change', this.switchMode.bind(this))
    radio.checked = localStorage.getItem('pricing_mode') == 'monthly';
    this.switchMode({ target: radio });
  }

  modeTargetDisconnected(radio) {
    radio.removeEventListener('change', this.switchMode.bind(this))
  }

  switchMode(event) {
    if (event.target.checked) {
      this.annualTarget.classList.add('hidden')
      this.monthlyTarget.classList.remove('hidden')
      localStorage.setItem('pricing_mode', 'monthly')
    } else {
      this.annualTarget.classList.remove('hidden')
      this.monthlyTarget.classList.add('hidden')
      localStorage.setItem('pricing_mode', 'annual')
    }
  }
}
