import NestedForm from "@stimulus-components/rails-nested-form";
import validator from "validator";

export default class extends NestedForm {
  static targets = ["input", "item", "delay", "option", "template", "target"];

  connect() {
    super.connect();
  }

  inputTargetConnected(input) {
    input.addEventListener("change", this.onChange.bind(this));
    input.addEventListener("keydown", this.onKeyDown.bind(this));
    input.addEventListener("paste", this.onPaste.bind(this));
  }

  inputTargetDisconnected(input) {  // Corrected method name
    input.removeEventListener("change", this.onChange.bind(this));
    input.removeEventListener("keydown", this.onKeyDown.bind(this));
    input.removeEventListener("paste", this.onPaste.bind(this));
  }

  onPaste(event) {
    event.preventDefault();
    const pastedText = (event.clipboardData || window.clipboardData).getData("text");
    const lines = pastedText.split("\n").map(line => line.trim()).filter(Boolean);

    lines.forEach((line, index) => {
      if (index === 0) {
        event.target.value = line;
      } else {
        this.add(event);
        const newInput = this.lastInput();
        newInput.value = line;
      }
    });

    if (lines.length > 1) {
      this.add(event);
    }
  }

  add(event) {
    if (event) event.preventDefault();

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML("beforeend", content);

    const customEvent = new CustomEvent("rails-nested-form:add", { bubbles: true });
    this.element.dispatchEvent(customEvent);

    const lastInput = this.lastInput();
    lastInput.focus();
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.onChange(event);
    } else if (event.target !== this.lastInput() && event.target.value === "") {
      this.remove(event);
    }
  }

  onChange(event) {
    const lastInput = this.lastInput();
    if (lastInput && validator.isURL(lastInput.value)) {
      this.add();
    }
  }

  lastInput() {
    return this.inputTargets[this.inputTargets.length - 1];
  }

  openAll() {
    const mode = this.openMode();

    if (mode === "open_separate_window") {
      this.openNewWindow();
    } else if (mode === "open_group_by_host") {
      this.openGroupByHost();
    } else {
      this.openNewTab();
    }
  }

  openNewWindow() {
    const delay = this.opeDelay();
    this.urlList().forEach((url, index) => {
      setTimeout(() => {
        window.open(url, "_blank", this.windowFeatures());
      }, index * delay);
    });
  }

  openNewTab() {
    const delay = this.opeDelay();
    this.urlList().forEach((url, index) => {
      setTimeout(() => {
        window.open(url, "_blank");
      }, index * delay);
    });
  }

  openGroupByHost() {
    const delay = this.opeDelay();
    const groupedUrls = this.groupedUrls();

    for (const domain in groupedUrls) {
      const urls = groupedUrls[domain];

      if (urls.length > 0) {
        const newWindow = window.open(urls[0], "_blank", this.windowFeatures());

        if (newWindow) {
          newWindow.focus();

          urls.slice(1).forEach((url, index) => {
            setTimeout(() => {
              const tab = newWindow.open(url, newWindow.name);
              if (tab) {
                tab.focus();
              }
            }, (index + 1) * delay);
          });
        } else {
          alert("Popup blocked! Please allow pop-ups for this site.");
        }
      }
    }
  }

  opeDelay() {
    const delayValue = parseInt(this.delayTarget.value, 10);
    return isNaN(delayValue) ? 1000 : delayValue * 1000;
  }

  openMode() {
    return this.optionTargets.find(e => e.checked)?.value || "open_new_tab";
  }

  urlList() {
    return this.inputTargets
      .map(e => validator.isURL(e.value) ? this.ensureHttps(e.value) : null)
      .filter(Boolean);
  }

  groupedUrls() {
    return this.urlList().reduce((groups, url) => {
      const domain = new URL(url).hostname;
      groups[domain] = groups[domain] || [];
      groups[domain].push(url);
      return groups;
    }, {});
  }

  windowFeatures() {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    return `width=${screenWidth},height=${screenHeight},scrollbars=yes,resizable=yes,toolbar=yes,location=yes,menubar=yes,status=yes,noopener,noreferrer`;
  }

  ensureHttps(url) {
    return /^https?:\/\//i.test(url) ? url : `https://${url}`;
  }

  copyUrls() {
    const urls = this.urlList().join("\n");
    navigator.clipboard.writeText(urls)
      .then(() => alert("URLs copied to clipboard!"))
      .catch(() => alert("Failed to copy URLs to clipboard."));
  }

  exportUrlsToCSV() {
    const urls = this.urlList();
    // Create CSV content
    let csvContent = "URL\n" + urls.join("\n");

    // Create a Blob with CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "urls.csv");
    link.style.display = "none";

    // Append the link, click to download, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  clearAll(event) {
    this.itemTargets.forEach(e => e.remove());
    this.add(event);
  }
}
