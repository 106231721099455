import { Controller } from "@hotwired/stimulus"
import MobileDetect from "mobile-detect"
import device from "current-device";

export default class extends Controller {
  static targets = ['button', 'aside', 'checkbox', 'link']

  initialize() {
    this.body = document.body;
    this.toggle = this.toggle.bind(this);
    this.device = new MobileDetect(window.navigator.userAgent);
    this.handleMobileTapInsideMain = this.handleMobileTapInsideMain.bind(this);
    this.setActiveLink = this.setActiveLink.bind(this);
  }

  connect() {
    const that = this;
    const storedIsCollapsed = device.mobile()
    ? localStorage.getItem("isSidebarCollapsed") === "true"
    : false;
    if (localStorage.getItem("isSidebarCollapsed") === "true") {
      this.toggle();
    }
    document.addEventListener("mousedown", this.handleMobileTapInsideMain);
    this.setActiveLink();

    const toggleSidebar = document.getElementById('toggleSidebar');
    if (toggleSidebar) {
      toggleSidebar.addEventListener('click', this.toggle.bind(this))
    }
  }

  disconnect() {
    const toggleSidebar = document.getElementById('toggleSidebar');
    if (toggleSidebar) {
      toggleSidebar.removeEventListener('click', this.toggle.bind(this))
    }
  }

  toggle() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
    toggleClass(document.body, 'sidebar-collapsed');
    localStorage.setItem("isSidebarCollapsed", this.checkboxTarget.checked ? "true" : "false");
  }

  handleMobileTapInsideMain(event) {
    const main = document.querySelector("#main-content");
    const header = document.querySelector("#main-header");
    const isClickInsideMain = main?.contains(event.target) && !header?.contains(event.target);

    if (device.mobile() && isClickInsideMain) {
      this.toggle();
    }
  }

  setActiveLink() {
    // Get the current path
    const currentPath = window.location.pathname;

    // Loop through each nav link to check if its href matches the current path
    this.linkTargets.forEach((link) => {
      const targetPath = link.getAttribute('href')
      if (currentPath === targetPath || currentPath.includes(targetPath)) {
        link.classList.add('bg-primary-50', 'text-primary-600', 'dark:bg-gray-700', 'dark:text-white');
      } else {
        link.classList.remove('bg-primary-50', 'text-primary-600', 'dark:bg-gray-700', 'dark:text-white');
      }
    });
  }
}
