import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

const classNames = {
  containerOuter: 'choices',
  containerInner: 'choices__inner',
  input: 'choices__input',
  inputCloned: 'choices__input--cloned',
  list: 'choices__list',
  listItems: 'choices__list--multiple',
  listSingle: 'choices__list--single',
  listDropdown: 'choices__list--dropdown',
  item: 'choices__item',
  itemSelectable: 'choices__item--selectable',
  itemDisabled: 'choices__item--disabled',
  itemChoice: 'choices__item--choice',
  placeholder: 'choices__placeholder',
  group: 'choices__group',
  groupHeading: 'choices__heading',
  button: 'choices__button',
  activeState: 'is-active',
  focusState: 'is-focused',
  openState: 'is-open',
  disabledState: 'is-disabled',
  highlightedState: 'is-highlighted',
  selectedState: 'is-selected',
  flippedState: 'is-flipped',
  loadingState: 'is-loading',
  noResults: 'has-no-results',
  noChoices: 'has-no-choices'
}

export default class extends Controller {
  static targets = ['select', 'options']

  initialize () {
    this.element['choices'] = this
    this.refresh = this.refresh.bind(this)
    this.async = this.async.bind(this)
    this.add = this.add.bind(this)
    this.clear = this.clear.bind(this)
    this.remove = this.remove.bind(this)
    this.search = this.search.bind(this)
    this.update = this.update.bind(this)
    this.filter = this.filter.bind(this)
    this.options = this.options.bind(this)
    this.setChoicesValue = this.setChoicesValue.bind(this)
    this.addItemOnCustomKeyPress = this.addItemOnCustomKeyPress.bind(this)
    this.optionsReducer = this.optionsReducer.bind(this)
    this.updateActiveSearch = this.updateActiveSearch.bind(this)
    this.searchPath = this.element.dataset.searchPath
    this.asyncPath = this.element.dataset.asyncPath
    this.forceOption = this.element.dataset.forceOption || true
    this.key = this.selectTarget.dataset.key
  }

  connect () {
    setTimeout(this.setup.bind(this), 5)
  }

  modeTargetConnected(checkbox) {
    var that = this;

    checkbox.addEventListener("change", function() {
      that.dispatch("change", { detail: { field_name: 'mode', field_value: this.checked } });
    })
  }

  setup () {
    const that = this;
    let choicesOptions = { ...this.options(),
      valueComparer: (a, b) => (a?.toString().trim() || '') === (b?.toString().trim() || ''),
      fuseOptions: {
        threshold: 0.1
      },
      addItemFilter: (value) => {
        that.addItemOnCustomKeyPress(value);
        return true;
      },
      noResultsText: () => {
        return `Press Enter to add <b>"${that.choices.input.value}"</b>`;
      }
    }
    this.choices = new Choices(this.selectTarget, choicesOptions)
    this.input = this.element.querySelector('input')
    this.refresh()
    this.async()
    this.updateActiveSearch(true)
    if (this.searchPath) {
      // All that is only relevant if searchPath is set - I for instance don't need AJAX request in many cases.
      this.refresh()
      this.input.addEventListener('input', this.search)
      this.selectTarget.addEventListener('change', this.refresh)
      this.selectTarget.addEventListener('addItem', this.add)
      this.selectTarget.addEventListener('removeItem', this.remove)
    } else {
      this.selectTarget.addEventListener('change', function() {
        that.updateActiveSearch()
      })
      this.choices.input.element.addEventListener("keydown", function (target) {
        if (target.key === "Enter") {
          that.setChoicesValue(that.choices.input.value);
        }
      });
    }
  }

  disconnect () {
    if (this.searchPath) {
      this.input.removeEventListener('input', this.search)
      this.selectTarget.removeEventListener('change', this.refresh)
      this.selectTarget.removeEventListener('addItem', this.add)
      this.selectTarget.removeEventListener('removeItem', this.remove)
    }
    try {
      this.choices.destroy()
    } catch {}
    this.choices = undefined
  }

  refresh () {
    this.choices.setChoices([], 'value', 'label', true)
    if (this.hasOptionsTarget) {
      ;[...this.optionsTarget.children].forEach(this.append.bind(this))
    }
  }

  async () {
    const that = this;
    if (this.asyncPath) {
      this.choices.setChoices(async () => {
        try {
          const response = await fetch(that.asyncPath);
          return response.json();
        } catch (err) {
          console.error(err);
        }
      }, 'value', 'label', false);
    }
  }

  clear () {
    this.choices.removeActiveItems();
  }

  append (option) {
    if (
      ![...this.selectTarget.options].some(o => {
        return o.label === option.label
      })
    )
      this.choices.setChoices([option], 'value', 'label', false)
  }

  add (event) {
    if (this.hasOptionsTarget) {
      const option = [...this.optionsTarget.children].find(option => {
        return option.label === event.detail.label
      })
      if (option) {
        option.setAttribute('selected', '')
      } else {
        const newOption = document.createElement('option')
        newOption.setAttribute('label', event.detail.label)
        newOption.setAttribute('value', event.detail.value)
        newOption.setAttribute('selected', '')
        this.optionsTarget.appendChild(newOption)
      }
    }
  }

  remove (event) {
    if (this.hasOptionsTarget) {
      const option = [...this.optionsTarget.children].find(item => {
        return item.label === event.detail.label
      })
      if (option)
        this.searchPath ? option.remove() : option.removeAttribute('selected')
    }
    if (this.forceOption && !this.selectTarget.options.length)
      this.selectTarget.add(document.createElement('option'))
  }

  search (event) {
    if (event.target.value) {
      fetch(this.searchPath + event.target.value, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      })
        .then(response => response.json())
        .then(this.update)
    } else {
      this.refresh()
    }
  }

  update (data) {
    this.choices.setChoices(data.filter(this.filter), 'value', 'label', true)
  }

  filter (item) {
    return ![...this.selectTarget.options].some(option => {
      return option.label === item.label
    })
  }

  options () {
    return 'silent renderChoiceLimit maxItemCount addItems removeItems removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText'
      .split(' ')
      .reduce(this.optionsReducer, { classNames: classNames })
  }

  optionsReducer (accumulator, currentValue) {
    if (currentValue in this.element.dataset || this.element.dataset.hasOwnProperty(currentValue)) {
      let value = this.element.dataset[currentValue];
      accumulator[currentValue] = ['true', 'false'].includes(value) ? JSON.parse(value) : value;
    }
    return accumulator;
  }

  setChoicesValue(value) {
    if (value) {
      this.choices.setValue([value]);
      this.choices.clearInput();
      this.choices.hideDropdown(true);
      this.updateActiveSearch()
    }
  }

  addItemOnCustomKeyPress(value) {
    const addItemCharacters = ',; ';
    if (this.choices && value && addItemCharacters.includes(value.slice(-1))) {
      let values = value.slice(0, -1)
      localStorage.setItem(this.key, JSON.stringify(values))
      this.setChoicesValue(values);
      this.updateActiveSearch()
    } else {
      localStorage.setItem(this.key, JSON.stringify([]))
      this.updateActiveSearch()
    }
  }

  updateActiveSearch(init = false) {
    const accordion = document.getElementById(`accordion-flush-${this.key}`)
    const accordionBody = document.getElementById(`accordion-flush-body-${this.key}`)
    const values = this.choices.getValue(true)

    if (accordion) {
      const badge = accordion.querySelector(`.badge-${this.key}`)
      if (values.length > 0) {
        accordion.classList.add('filled-search')
        accordionBody.classList.add('filled-search')
        badge.innerHTML = values.length
      } else {
        accordion.classList.remove('filled-search')
        accordionBody.classList.remove('filled-search')
        badge.innerHTML = values.length
      }
    }

    if (!init) {
      this.dispatch("change", { detail: { field_name: this.key, field_value: values } });
    }
  }
}