import CheckboxSelectAll from '@stimulus-components/checkbox-select-all';
import { Drawer } from 'flowbite'

export default class extends CheckboxSelectAll {
  static targets = ['drawer', 'form', 'modal', 'result']

  connect() {
    this.initModal();

    document.addEventListener("turbo:submit-end", (event) => {
      const buttonClicked = event.detail.formSubmission.submitter;

      if (buttonClicked) {
        if (buttonClicked.value == 'save_to_campagin') {
        }
      } else {
        console.log("Form submitted without a button (e.g., via Enter key).");
      }
    });
  }

  resultTargetConnected(element) {
    if (element.dataset.redirectUrl) {
      Turbo.visit(element.dataset.redirectUrl);
      element.remove();
    }
  }

  drawerTargetConnected($targetEl) {
    this.initDrawer($targetEl);
  }

  formTargetConnected(form) {
    form.addEventListener('submit', this.submitAction.bind(this))
  }

  formTargetDisconnected(form) {
    form.removeEventListener('submit', this.submitAction.bind(this))
  }

  submitAction(event) {
    const buttonClicked = event.detail

    console.log(buttonClicked, event.target, event.currentTarget)
    if (this.checked.length == 0) {
      event.preventDefault()
      this.showNotice("Apply a filter or select at least one recipient you'd like to add to a campaign.");
      return false;
    }
  }

  initDrawer($targetEl) {
    // options with default values
    const options = {
      placement: 'right',
      backdrop: true,
      bodyScrolling: false,
      edge: false,
      edgeOffset: '',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: $targetEl.id,
      override: true
    };

    this.drawer = new Drawer($targetEl, options, instanceOptions);
  }

  openDrawer(event) {
    const openUrl = event.currentTarget.dataset.openUrl;
    if (openUrl) {
      turboFetch(openUrl);
    }
    this.drawer.show();
  }

  closeDrawer() {
    this.drawer.hide();
  }

  initModal() {
    // set the modal menu element
    const modalId = `engagement-modal`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.modal = new Modal($targetEl, options, instanceOptions);
  }

  openModal(event) {
    const buttonClicked = event.currentTarget || event.target;

    if (this.checked.length === 0) {
      this.showNotice("Apply a filter or select at least one recipient you'd like to add to a campaign.");
      return;
    }

    const modalConfig = {
      'save_to_campaign': ['.save_to_campaign', '.create_new_campaign'],
      'create_new_campaign': ['.create_new_campaign', '.save_to_campaign']
    };

    if (modalConfig[buttonClicked.value]) {
      this.toggleVisibility(...modalConfig[buttonClicked.value]);
      this.modal.show();
    }
  }

  showNotice(message) {
    const notice = this.noticeTemplate(message);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = notice;

    const noticeElement = tempDiv.firstChild;
    document.body.appendChild(noticeElement);
  }

  toggleVisibility(showSelector, hideSelector) {
    this.removeClasses(showSelector, 'hidden');
    this.addClasses(hideSelector, 'hidden');
  }

  addClasses(querySelector, className) {
    document.querySelectorAll(querySelector).forEach(e => e.classList.add(className));
  }

  removeClasses(querySelector, className) {
    document.querySelectorAll(querySelector).forEach(e => e.classList.remove(className));
  }

  closeModal() {
    this.modal.hide()
  }

  noticeTemplate(content) {
    return `<div
              data-controller="notification"
              data-notification-delay-value="5000"
              data-transition-enter-from="opacity-0 translate-x-6"
              data-transition-enter-to="opacity-100 translate-x-0"
              data-transition-leave-from="opacity-100 translate-x-0"
              data-transition-leave-to="opacity-0 translate-x-6"
              class="fixed mx-auto w-1/2 bottom-5 left-5 right-5 transition transform duration-1000 flex items-center p-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
            >
              <div class="text-base font-normal">${content}</div>
              <button data-action="notification#hide" type="button" class="ms-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-1" aria-label="Close">
                <span class="sr-only">Close</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                </svg>
              </button>
            </div>`;
  }
}
