import { Controller } from "@hotwired/stimulus"
import sanitizeHtml from 'sanitize-html'

export default class extends Controller {
  static targets = ["body", "template", "form", "plainText", "trixEditor"]

  connect() {
    const that = this;
    this.lastCursorPosition = null;
    this.addTrixToolbar = this.addTrixToolbar.bind(this)
    this.addTrixToolbars = this.addTrixToolbars.bind(this)
    this.selectUnit = this.selectUnit.bind(this)
    this.filterUser = this.filterUser.bind(this)
    this.trixEditor = this.trixEditorTarget.querySelector("trix-editor")
    this.trixInput = document.getElementById('email_body_trix_input_email')
    this.plainTextInput = document.getElementById('email_body_input')
    this.disablePlainTextEditor()

    document.addEventListener("trix-initialize", function(event) {
      const trixEditor = event.target;

      trixEditor.addEventListener("paste", function(ev) {
        const clipboardData = ev.clipboardData || window.clipboardData;
        if (!clipboardData) return;

        const html = clipboardData.getData("text/html");
        const plainText = clipboardData.getData("text/plain");
        if (html) {
          // Prevent default paste behavior
          ev.preventDefault();
          ev.stopPropagation();
          // Undo the paste
          trixEditor.editor.undo();
          // Insert the HTML as-is
          trixEditor.editor.insertHTML(html);
        } else if (plainText) {
          // If plain text is pasted, wrap it in a <pre> tag for code
          ev.preventDefault();
          ev.stopPropagation();
          // Undo the paste
          trixEditor.editor.undo();
          // Insert the HTML as-is
          trixEditor.editor.insertHTML(sanitizeHtml(plainText));
        }
      });
    });

    document.addEventListener('trix-initialize', this.addTrixToolbars, { once: true });
    this.templateTarget.addEventListener('change', function(){
      turboFetch(that.templateTarget.dataset.templateUrl.replace('__ID__', this.value), function(){
      })
    })
  }

  addTrixToolbars(event) {
    const that = this;
    const editor = event.target.editor;
    let toolbar = document.querySelector('.trix-button-group-spacer')
    toolbar.classList.add('ms-4')
    Array.from(['first_name', 'last_name', 'company', 'phone', 'unsub']).forEach(key => this.addTrixToolbar(toolbar, key))

    // Event listener for cursor change
    editor.element.addEventListener('trix-selection-change', function(event) {
      that.lastCursorPosition = editor.getSelectedRange();
    });

    // Event listener for custom button
    toolbar.addEventListener('click', function(event) {
      if (event.target.getAttribute('data-trix-attribute') === 'keytext') {
        const keyText = event.target.getAttribute('data-trix-value');
        if (that.lastCursorPosition !== null) {
          editor.setSelectedRange(that.lastCursorPosition);
          editor.insertString(keyText);
        } else {
          const currentPosition = editor.getSelectedRange();
          editor.insertString(keyText, currentPosition[0]);
        }
      }
    });
  }

  addTrixToolbar(toolbar, button) {
    toolbar.insertAdjacentHTML(
      'beforeend',
      `<span class="trix-button-group-button text-gray-900 dark:text-gray-400 mx-1.5 text-sm hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-700 rounded py-1 px-2 inline-flex items-center justify-center bg-white border-gray-200 border" data-trix-attribute="keytext" data-trix-value="[${button}]" title="${button}">[${button}]</span>`
    );
  }

  disablePlainTextEditor() {
    this.plainTextTarget.setAttribute('disabled', true)
    this.plainTextTarget.classList.add('disabled')
    this.plainTextInput.style.disabled = true
  }

  enablePlainTextEditor() {
    this.plainTextTarget.removeAttribute('disabled')
    this.plainTextTarget.classList.remove('disabled')
    this.plainTextInput.style.disabled = false
  }

  disableTrixEditor() {
    this.trixEditor.setAttribute('contenteditable', 'false')
    this.trixEditor.classList.add('disabled')
    this.trixInput.style.disabled = true
  }

  enableTrixEditor() {
    this.trixEditor.setAttribute('contenteditable', 'true')
    this.trixEditor.classList.remove('disabled')
    this.trixInput.style.disabled = false
  }

  showPlainText() {
    // Synchronize the content from Trix to the plain text area
    console.log('showPlainText', this.plainTextInput, this.trixInput.value)
    this.plainTextInput.innerHTML = this.trixInput.value
    // Enable the plain text area and disable the Trix editor
    this.enablePlainTextEditor()
    this.disableTrixEditor()
  }

  showTrix() {
    // Synchronize the content from the plain text area to Trix
    console.log('showTrix', this.plainTextInput.value)
    this.trixInput.value = this.plainTextInput.value
    this.trixEditor.editor.loadHTML(this.plainTextInput.value)
    // Enable the Trix editor and disable the plain text area
    this.enableTrixEditor()
    this.disablePlainTextEditor()
  }
}
