import { Controller } from "@hotwired/stimulus"
// import { updateToolbars } from "../helpers/trix"

export default class extends Controller {
  static targets = ['response', 'fetch', 'link'];

  responseTargetConnected(element) {
    if (element.dataset.redirectUrl) {
      window.location = element.dataset.redirectUrl;
      element.remove();
    }

    if (element.dataset.openUrl) {
      turboFetch(element.dataset.openUrl);
    }
  }

  fetchTargetConnected(element) {
    element.addEventListener('click', this.fetchData.bind(this))
  }

  fetchTargetDisconnected(element) {
    element.removeEventListener('click', this.fetchData.bind(this))
  }

  fetchData(event) {
    const data = (event.currentTarget || event.target).dataset;
    this.fetchTargets.forEach(e => e.classList.remove('active'));

    if (data.url) {
      turboFetch(data.url, function() {
        element.classList.add('active');
      })
    }
  }

  connect() {
    this.switchThemeMode = this.switchThemeMode.bind(this);
    this.setThemeMode = this.setThemeMode.bind(this);
    this.howToModal = this.initHowToModal();
    const that = this;
    // this.initDatepickers = initDatepickers()

    if (this.hasLinkTarget) {
      this.setActiveLink();
    }

    document.addEventListener("turbo:before-fetch-response", function (event) {
      // console.log("turbo:before-fetch-response", event);
    })

    document.addEventListener("turbo:before-stream-render", (event) => {
      // console.log("turbo:before-stream-render", event);
      // The target frame where the stream is being rendered
      const frameElement = event.target;
      // Alternatively, if you're interested in Turbo Stream elements
      if (frameElement.tagName.toLowerCase() === "turbo-stream") {
        // console.log("Turbo Frame ID:", frameElement.id);
        // Do something with the stream element
        // console.log("Turbo Stream target:", frameElement.getAttribute('target'));
        // console.log("Turbo Stream action:", frameElement.getAttribute("action"));
      }

      const originalRender = event.detail.render
      event.detail.render = function (streamElement) {
        originalRender(streamElement)

        // if (frameElement.tagName.toLowerCase() === "turbo-stream") {
        //   const callback = frameElement.dataset.init;
        //   // console.log("Turbo Frame Callback:", callback);
        //   if (![false, 'false'].includes(callback)) {
        //     let target = document.getElementById(frameElement.getAttribute('target'));
        //     if (target) {
        //       // initTurboFlowbite(target)
        //     }
        //   }
        // }
        // updateToolbars(event)
      }
    })

    // TURBO:FRAME-LOAD fires when a <turbo-frame> element is navigated and
    // finishes loading (fires after turbo:frame-render). The specific
    // <turbo-frame> element is the event target.
    document.addEventListener("turbo:frame-load", (event) => {
      // console.log("turbo:frame-load", event);
    });

    this.setThemeMode()
    this.initCustomDropdowns()
    document.addEventListener('intro:onboard', this.showHowTo.bind(this));
  }

  disconnect() {
    document.removeEventListener('intro:onboard', this.showHowTo.bind(this));
  }

  switchThemeMode() {
    let mode = localStorage.getItem('color-theme');
    this.setThemeMode(mode == "light" ? "dark" : "light")
  }

  setThemeMode(mode) {
    mode ||= localStorage.getItem('color-theme');
    const $body = document.body;
    const $html = document.documentElement;

    this.setBsThemeMode(mode);
    switch (mode) {
      case 'dark':
          $html.classList.remove('light');
          $html.classList.add('dark');
          $html.dataset.theme = 'dark';
          $html.dataset.bsTheme = 'dark';
          $body.dataset.colorScheme = 'dark';
          $body.dataset.bsTheme = 'dark';
          localStorage.setItem('color-theme', 'dark');
        break;

      default:
        $html.classList.remove('dark');
        $html.classList.add('light');
        $html.dataset.theme = 'light';
        $html.dataset.bsTheme = 'light';
        $body.dataset.colorScheme = 'light';
        $body.dataset.bsTheme = 'light';
        localStorage.setItem('color-theme', 'light');
        break;
    }
  }

  setBsThemeMode(mode) {
    const $darkTarget = document.getElementById('cssDark');
    if (!$darkTarget) return;

    switch (mode) {
      case 'dark':
        $darkTarget.removeAttribute('disabled')
        break;
      default:
        $darkTarget.setAttribute('disabled', true)
        break;
    }
  }

  initCustomDropdowns() {
    const elements = document.querySelectorAll(`[data-custom-dropdown-toggle]`)
    Array.from(elements).forEach(e => this.initCustomDropdown(e))
  }

  initCustomDropdown(element) {
    const elementId = element.dataset.customDropdownToggle
    const buttonId = element.getAttribute('id')

    // set the dropdown menu element
    const $targetEl = document.getElementById(elementId);

    // set the element that trigger the dropdown menu on click
    const $triggerEl = document.getElementById(buttonId);

    // options with default values
    const options = {
        placement: element.dataset.dropdownPlacement || 'bottom',
        triggerType: element.dataset.dropdownTrigger || 'click',
        offsetSkidding: 0,
        offsetDistance: 10,
        delay: 300,
        ignoreClickOutsideClass: false,
        onHide: () => {
          $triggerEl.disabled = false;
          $triggerEl.classList.remove('is-active-dropdown');
          $targetEl.classList.add("scale-y-0"); // Hide the menu with animation
          $targetEl.classList.remove("scale-y-100");
          const $headEl = $triggerEl.closest('header');
          const scrollY = window.scrollY;
          if ($headEl) {
            $headEl.classList.remove('is-dropdown-show');
            if ($headEl.dataset.activeClass) {
              if (scrollY == 0) {
                header.classList.remove(...$headEl.dataset.activeClass.split(' '));
              }
            }
            if ($headEl.dataset.inactiveClass) {
              if (scrollY > 0) {
                header.classList.add(...$headEl.dataset.inactiveClass.split(' '));
              }
            }
          }
        },
        onShow: () => {
          $triggerEl.disabled = true;
          $triggerEl.classList.add('is-active-dropdown');
          $targetEl.classList.remove("scale-y-0");
          $targetEl.classList.add("scale-y-100");
          const $headEl = $triggerEl.closest('header');
          const scrollY = window.scrollY;
          if ($headEl) {
            $headEl.classList.add('is-dropdown-show');
            if ($headEl.dataset.activeClass) {
              header.classList.add(...$headEl.dataset.activeClass.split(' '));
            }
            if ($headEl.dataset.inactiveClass) {
              header.classList.remove(...$headEl.dataset.inactiveClass.split(' '));
            }
          }
        },
        onToggle: () => {
        },
    };

    // instance options object
    const instanceOptions = {
      id: elementId,
      override: true
    };

    return new Dropdown($targetEl, $triggerEl, options, instanceOptions);
  }

  initHowToModal() {
    this.modalTarget = document.getElementById('howto-modal');
    // options with default values
    this.options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'howto-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-[100]',
      closable: true,
      onHide: () => {
        document.querySelectorAll('.howto-backdrop').forEach((element) => {
          element.remove();
        });
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    this.instanceOptions = {
      id: 'howto-modal',
      override: true
    };

    return new Modal(this.modalTarget, this.options, this.instanceOptions);
  }

  showHowTo() {
    this.howToModal.show();
  }

  closeHowTo() {
    this.howToModal.hide();
  }

  setActiveLink() {
    const currentPath = window.location.pathname;
    let bestMatch = null;
    let highestScore = -1;

    this.linkTargets.forEach((link) => {
      const targetPath = link.getAttribute('href');
      let score = 0;

      if (currentPath === targetPath) {
        // Exact match gets the highest score
        score = 100;
      } else if (currentPath.startsWith(targetPath)) {
        // Penalize based on the length difference to avoid partial matches
        score = 50 - (currentPath.length - targetPath.length);
      }

      if (score > highestScore) {
        highestScore = score;
        bestMatch = link;
      }
    });

    // Clear previous active classes
    this.linkTargets.forEach((link) => {
      const group = link.closest('.group');
      if (group) group.classList.remove('active');
    });

    // Set active class to the best match
    if (bestMatch && bestMatch.closest('.group')) {
      bestMatch.closest('.group').classList.add('active');
    }
  }
}
