import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['accordion', 'accountModal']

  connect() {
    if (this.hasAccordionTarget) {
      this.initAccordion();
    }
    if (this.hasAccountModalTarget) {
      this.initAccountModal();
    }
  }

  initAccordion() {
    const accordionItems = [
      {
        id: 'accordion-schedule-heading',
        triggerEl: document.querySelector('#accordion-schedule-heading'),
        targetEl: document.querySelector('#accordion-schedule-body'),
        active: false
      },
      {
        id: 'accordion-account-heading',
        triggerEl: document.querySelector('#accordion-account-heading'),
        targetEl: document.querySelector('#accordion-account-body'),
        active: false
      },
      {
        id: 'accordion-unsubscribe-heading',
        triggerEl: document.querySelector('#accordion-unsubscribe-heading'),
        targetEl: document.querySelector('#accordion-unsubscribe-body'),
        active: false
      }
    ];

    // options with default values
    const options = {
      alwaysOpen: false,
      activeClasses: 'group active bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
      inactiveClasses: 'text-gray-500 dark:text-gray-400',
      onOpen: (item) => {
        const $activeEl = this.element.querySelector('.active');
        history.pushState(null, null, `#${$activeEl.dataset.id}`);
      },
      onClose: (item) => {
      },
      onToggle: (item) => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: 'accordion-collapse',
      override: true
    };

    this.accordion = new Accordion(this.accordionTarget, accordionItems, options, instanceOptions);
    this.activateAccordionFromHash();
  }

  activateAccordionFromHash() {
    let hash = window.location.hash.replace("#", ""); // Strip the #

    if (!hash) {
      this.accordion.open(`accordion-schedule-heading`)
    } else {
      this.accordion.open(`accordion-${hash}-heading`)
    }
  }

  initAccountModal() {
    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: 'account-modal',
      override: true
    };

    this.accountModal = new Modal(this.accountModalTarget, options, instanceOptions);
  }

  openAccountModal(event) {
    const data = event.target.dataset.metadata

    if (data) {
      this.accountModalTarget.querySelector('#daily_emails_limit').value = data.daily_emails_limit
      this.accountModalTarget.querySelector('#sender_name').value = data.sender_name
      this.accountModalTarget.querySelector('#send_emails_from').value = data.send_emails_from
    }

    this.accountModal.show()
  }

  closeAccountModal() {
    this.accountModal.hide()
  }
}
