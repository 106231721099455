import CheckboxSelectAll from '@stimulus-components/checkbox-select-all';

export default class extends CheckboxSelectAll {
  static targets = ["modal", "checkbox", "checkboxAll", "buttonAddSelected", "selectPage", 'lazy'];

  connect() {
    try {
      super.connect();
      this.updateSelectIds = this.updateSelectIds.bind(this);
      this.toggleAddButton = this.toggleAddButton.bind(this);
      this.updateTotalSelected = this.updateTotalSelected.bind(this);
      this.changePage = this.changePage.bind(this);
      this.listModal = this.initListModal();
      this.bulkAddModal = this.initBulkAddModal();

      this.toggleAddButton(); // Initial call to set the button state
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  buttonAddSelectedConnected(button) {
    button.addEventListener('click', this.beforeAdd().bind(this))
  }

  buttonAddSelectedDisconnect(button) {
    button.removeEventListener('click', this.beforeAdd().bind(this))
  }

  toggleAddButton() {
    try {
      this.updateSelectIds();
      this.updateTotalSelected();
    } catch (error) {
      console.error("Error in toggleAddButton:", error);
    }
  }

  toggle(e) {
    try {
      super.toggle(e);
      this.updateSelectIds();
      this.updateTotalSelected();
    } catch (error) {
      console.error("Error in toggle:", error);
    }
  }

  updateSelectIds() {
    try {
      if (this.checked.length > 0) {
        this.buttonAddSelectedTarget.classList.remove("opacity-65", "cursor-not-allowed");
        this.buttonAddSelectedTarget.disabled = false;
      } else {
        this.buttonAddSelectedTarget.classList.add("opacity-65", "cursor-not-allowed");
        this.buttonAddSelectedTarget.disabled = true;
      }
    } catch (error) {
      console.error("Error in updateSelectIds:", error);
    }
  }

  updateTotalSelected() {
    try {
      const totalBadge = document.querySelector('.total-selected');
      if (totalBadge) {
        const value = this.checked.length;
        totalBadge.innerHTML = value;

        const formElement = this.buttonAddSelectedTarget.closest('form');
        if (formElement) {
          if (value > 0) {
            formElement.classList.add('is-selected');
          } else {
            formElement.classList.remove('is-selected');
          }
        }
      }
    } catch (error) {
      console.error("Error in updateTotalSelected:", error);
    }
  }

  changePage(e) {
    try {
      const triggerButton = document.getElementById('searchAction');
      if (triggerButton) {
        document.getElementById('page').value = e.currentTarget.value;
        triggerButton.click();
      }
    } catch (error) {
      console.error("Error in changePage:", error);
    }
  }

  submitEnd() {
    try {
      if (this.checkboxAllTarget) {
        this.checkboxAllTarget.checked = false;
      }
    } catch (error) {
      console.error("Error in submitEnd:", error);
    }
  }

  beforeAdd(event) {
    try {
      event.preventDefault();
      const availableCreditInput = document.getElementById('available_credits').value || 0;
      const totalChecked = this.checked;
      const modal = document.getElementById('bulk-add-modal');
      if (modal) {
        modal.modal('show');
      }
      // if (totalChecked > availableCreditInput) {
      //   this.modaTarget.modal('show')
      // }
    } catch (error) {
      console.error("Error in beforeAdd:", error);
    }
  }

  initModal(modal_id) {
    const that = this;
    // set the modal menu element
    const $targetEl = document.getElementById(modal_id);

    if (!$targetEl) { return }

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        `${modal_id} bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40`,
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modal_id,
      override: true
    };

    return new Modal($targetEl, options, instanceOptions);
  }

  initListModal() {
    return this.initModal('list-modal');
  }

  initBulkAddModal() {
    return this.initModal('bulk-add-modal');
  }

  openListModal() {
    this.listModal.show();
  }

  closeListModal() {
    this.listModal.hide();
  }

  openBulkAddModal() {
    this.bulkAddModal.show();
  }

  closeBulkAddModal() {
    this.bulkAddModal.hide();
  }
}
