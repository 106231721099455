import CheckboxSelectAll from '@stimulus-components/checkbox-select-all';

export default class extends CheckboxSelectAll {
  static targets = ['form', 'counter']

  toggle(e) {
    super.toggle(e)
    this.updateCounter()
    this.updateInputHidden()
  }

  refresh() {
    super.refresh()
    this.updateCounter()
    this.updateInputHidden()
  }

  updateInputHidden() {
    if (this.hasFormTarget) {
      if (this.checked.length > 0) {
        this.checked.forEach(e => this.handleCheckboxChange(e));
      } else {
        this.formTarget.querySelectorAll('.selected_ids').forEach(e => e.remove());
      }
    }
  }

  updateCounter() {
    if (this.hasCounterTarget) {
      if (this.checked.length > 0) {
        this.counterTarget.dataset.prevHTML = this.counterTarget.innerHTML;
        this.counterTarget.innerHTML = this.checked.length;
      } else {
        if (this.counterTarget.dataset.prevHTML) {
          this.counterTarget.innerHTML =  this.counterTarget.dataset.prevHTML;
        }
      }
    }
  }

  handleCheckboxChange(checkbox) {
    const form = this.formTarget;

    // Check if the checkbox is checked
    if (checkbox.checked) {
      // Create a hidden input
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = checkbox.name;  // Unique name for the hidden field
      hiddenInput.value = checkbox.value;
      hiddenInput.id = checkbox.value + '_hidden'; // Unique ID
      hiddenInput.classList.add('selected_ids')

      // Append the hidden input to the form
      form.appendChild(hiddenInput);
    } else {
      // Remove the hidden input if the checkbox is unchecked
      const hiddenInput = document.getElementById(checkbox.value + '_hidden');
      if (hiddenInput) {
        form.removeChild(hiddenInput);
      }
    }
  }
}