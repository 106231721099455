import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "follow"]

  followTargetConnected(button) {
    button.addEventListener('click', this.followClickHandle.bind(this))
  }

  followTargetDisconnected(button) {
    button.removeEventListener('click', this.followClickHandle.bind(this))
  }

  followClickHandle(event) {
    const url = event.target.dataset.url;

    if (url) {
      turboFetch(url, function() {
        console.log('Load', event);
      })
    }
  }
}
