import { Controller } from "@hotwired/stimulus";
import introJs from "intro.js";
import MobileDetect from 'mobile-detect';

// Connects to data-controller="intro"
export default class extends Controller {
  static targets = ["startButton"];
  static values = {
    steps: Array,  // Tour steps
    startLabel: String, // Label for the start button
    nextLabel: String,  // Label for the next button
    prevLabel: String,  // Label for the previous button
    doneLabel: String,  // Label for the done button
    autoStart: Boolean,  // Automatically start the tour
    firstLogin: Boolean,
    userId: String,
  };

  connect() {
    try {
      this.initIntro();
      this.bindStartButton();

      // Automatically start the tour if autoStart is enabled
      if (this.autoStartValue && this.shouldAutoStart()) {
        this.startTour();
      }

      if (this.isMobile() && this.shouldAutoStart()) {
        const toggleDrawerSearchFilter = document.getElementById('toggleDrawerSearchFilter')
        if (toggleDrawerSearchFilter) {
          toggleDrawerSearchFilter.click();
        }
        this.startTour();
      }

      if (this.firstLoginValue && this.shouldAutoStart()) {
        this.openOnboardModal();
      }
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  initIntro() {
    const that = this;
    // Initialize Intro.js instance
    this.intro = introJs();

    // Define steps from values or use default steps
    const steps = this.stepsValue.length ? this.stepsValue : this.defaultSteps();

    this.intro.setOptions({
      steps: steps,
      nextLabel: this.nextLabelValue || "Next",
      prevLabel: this.prevLabelValue || "Previous",
      doneLabel: this.doneLabelValue || "Done",
      showProgress: false,
      showBullets: true,
      disableInteraction: true,
      showTitlebar: false,
      showSkipButton: true
    })
    this.intro.onbeforechange((element) => {
      console.log('onbeforechange', element);
      that.highlightStep();

      that.beforeNextStep(element); // Call the before-next-step function
    })
    this.intro.oncomplete(() => {
      that.endTour();
      const element = document.querySelector('.search_step_11');
      if (element) {
        element.style.display = '';
      }
    })
    this.intro.onexit(() => {
      that.endTour();
      const element = document.querySelector('.search_step_11');
      if (element) {
        element.style.display = '';
      }
    });
  }

  beforeNextStep(element) {
    try {
      // Example of custom logic before moving to the next step
      const currentStep = this.intro._currentStep;
      if (currentStep === 1) {
        console.log("Performing specific action at step 2");
        // Add any specific logic needed before moving to step 2
        if (this.isMobile()) {
          const toggleDrawerSearchFilter = document.getElementById('toggleDrawerSearchFilter')
          if (toggleDrawerSearchFilter) {
            toggleDrawerSearchFilter.click();
          }
        }
      }
      if (currentStep === 2) {
        this.scrollToElement(element);
      }
      if (currentStep === 9) {
        const element = document.querySelector('.search_step_11');
        if (element) {
          element.style.display = 'inline-flex';
        }
      }
    } catch (error) {
      console.error("Error in beforeNextStep:", error);
    }
  }

  openOnboardModal() {
    this.dispatch("intro:onboard", { detail: {} });
  }

  isMobile() {
    const md = new MobileDetect(window.navigator.userAgent);
    return window.innerWidth <= 768 || md.mobile();
  }

  scrollToElement(element) {
    try {
      const scrollableContainer = document.getElementById('listScroll');
      const element = document.querySelector('.search_step_4');

      if (scrollableContainer) {
        const elementLeft = element.offsetLeft;
        const containerLeft = scrollableContainer.offsetLeft;

        scrollableContainer.scrollTo({
          left: elementLeft - containerLeft - 20,
          behavior: 'smooth'
        });
      }
    } catch (error) {
      console.error("Error in scrollToElement:", error);
    }
  }

  getScrollableParent(element) {
    let parent = element.parentElement;

    if (element.closest('.absolute.overflow-auto')) {
      return element.closest('.absolute.overflow-auto');
    }

    // Traverse up the DOM tree to find the closest horizontally scrollable parent
    while (parent) {
      const overflowX = window.getComputedStyle(parent).overflowX;
      if (overflowX === 'auto' || overflowX === 'scroll') {
        return parent;
      }
      parent = parent.parentElement;
    }

    // Return null if no scrollable parent is found
    return null;
  }

  bindStartButton() {
    // If a start button is defined, attach the click event
    if (this.hasStartButtonTarget) {
      this.startButtonTarget.addEventListener("click", () => this.startTour());
    }
  }

  startTour() {
    try {
      this.intro.start();
    } catch (error) {
      console.error("Error in startTour:", error);
    }
  }

  highlightStep() {
    try {
      const currentElement = this.intro._introItems[this.intro._currentStep]?.element;
      if (currentElement) {
        // currentElement.classList.add("ring-2", "ring-indigo-500", "p-2"); // Highlight element using Tailwind
      }
    } catch (error) {
      console.error("Error in highlightStep:", error);
    }
  }

  endTour() {
    try {
      console.log("Tour ended");
      // Mark the tour as completed in localStorage
      localStorage.setItem("introCompleted_" + this.userIdValue, "true");
    } catch (error) {
      console.error("Error in endTour:", error);
    }
  }

  shouldAutoStart() {
    // Check localStorage for introCompleted flag
    return localStorage.getItem("introCompleted_" + this.userIdValue) !== "true";
  }

  defaultSteps() {
    // Define default steps with tooltip positioning
    return [
      {"position":"right","intro":"Welcome to Aeroleads Search. You can find contacts of several leads using different filters here. Enter a value and then hit enter to filter for more than one values of a field.","element":".search_step_1"},
      {"intro":"Submit applied filter.", "element":".search_step_2"},
      {"intro":"Choose which list to save your contacts to.", "element":".search_step_3"},
      {"intro":"Add prospect to your list", "element":".search_step_4"},
      {"intro":"Go to the list", "element":".search_step_5"},
      {"intro":"Check this to select all results", "element":".search_step_6"},
      {"intro":"Save all of the selected results at once in your prospects lists.", "element":".search_step_7"},
      {"intro":"Save all the results at once to your list.", "element":".search_step_8"},
      {"intro":"Save your filter using this button", "element":".search_step_9"},
      {"intro":"View your saved filters here", "element":".search_step_10"},
      {"intro":"Reset applied filter.", "element":".search_step_11"}
    ];
  }
}
