import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "source", "form", "input", "submit", "cancel", "edit", "display", "text"]

  connect() {
    this.model        = this.data.get("model")       || "model"
    this.name         = this.data.get("name")        || "name"
    this.method       = this.data.get("method")      || "post"
    this.input_class  = this.data.get("input-class") || "input"
    this.url          = this.data.get("url")         || window.location.pathname

    if (!this.hasEditTarget) {
      if (!this.hasDisplayTarget) {
        this.sourceTarget.innerHTML = this.display()
      }
    }
  }

  toggle() {
    if (this.data.get("toggled") != 1) {
      console.log('Toggle')
      this.render()
    }
  }

  close(event) {
    if ((this.element.contains(event.target) === false) &&
      this.data.get("toggled") == 1) {

      this.render()
    }
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  render() {
    if (this.data.get("toggled") == 1) {
      if (!this.hasDisplayTarget) {
        this.sourceTarget.innerHTML = this.sourceTarget.dataset.displayHTML
      } else {
        this.displayTarget.classList.remove('hidden')
        this.formTarget.classList.add('hidden')
      }
      this.data.set("toggled", 0)
    } else {
      if (!this.hasDisplayTarget) {
        this.sourceTarget.dataset.displayHTML = this.sourceTarget.innerHTML
        this.sourceTarget.innerHTML = this.form()
      } else {
        this.displayTarget.classList.add('hidden')
        if (!this.hasFormTarget) {
          this.sourceTarget.insertAdjacentHTML('beforeend', this.form());
        } else {
          this.formTarget.classList.remove('hidden')
        }
      }
      this.data.set("toggled", 1)
    }
  }

  cancel() {
    this.render()
  }

  display() {
    return `
      <div class="flex items-center space-x-2">
        <div data-target="inline-edit.source">
          ${this.sourceTarget.innerHTML}
        </div>
        <button data-action="click->inline-edit#toggle" data-target="inline-edit.edit">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path></svg>
        </button>
      </div>
    `
  }

  form() {
    return `
      <form class="px-4" action="${this.post_url}" accept-charset="UTF-8" data-turbo-stream="true" data-turbo="true" data-action="turbo:submit-end->inline-edit#cancel" data-target="inline-edit.form" method="${this.method}">
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="_method" value="patch">
        <input type="hidden" name="format" value="turbo_stream">
        <input type="hidden" name="authenticity_token" value="${this.authenticity_token}">
        <div class="min-w-md flex items-center space-x-4">
          <input type="hidden" name="commit" value="${this.name}"/>
          <input type="text" value="${this.input_value}" name="${this.model}[${this.name}]" class="${this.input_class}" id="${this.model}_${this.name}" data-target="inline-edit.input"/>
          <div class="flex items-center space-x-2 rtl:space-x-reverse">
            <button data-action="click->inline-edit#submit" type="button" data-target="inline-edit.submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
            <button type="button" data-action="click->inline-edit#cancel" data-target="inline-edit.cancel" class="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
          </div>
        </div>
      </form>
    `
  }

  get input_value() {
    return this.textTarget.textContent.trim()
  }

  get post_url() {
    return this.url
  }

  get authenticity_token() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }
}
