import CheckboxSelectAll from '@stimulus-components/checkbox-select-all';
import Dropzone from "dropzone";

const LIMIT_UPLOAD = 500;
export default class extends CheckboxSelectAll {
  static targets = ['modal', "input", "dropzone", "tableHeader", "tableBody", "preview", "flash", 'searchForm', 'buttonAction'];

  clearFilter(event) {
    Array.from(document.querySelectorAll('[data-close-filter]')).forEach(element => element.click());

    event.target.closest('form').requestSubmit();
  }

  resetTable() {
    // Clear the table body
    this.tableBodyTarget.innerHTML = "";
    this.totalRows = 0;
    this.inputTarget.value = '';
    this.urlTarget.value = '';
    this.previewTarget.classList.add('hidden');
  }

  connect() {
    this.initUploadModal();
    this.initListModal();
  }

  dropzoneTargetConnected() {
    this.initializeDropzone();
  }

  flashTargetConnected() {
    this.uploadModal.hide();

    if (this.hasSearchFormTarget) {
      this.searchFormTarget.requestSubmit();
    }
  }

  initializeDropzone() {
    try {
      const that = this;

      this.dropzone = new Dropzone(this.dropzoneTarget, {
        url: "/", // No actual upload
        autoProcessQueue: false,
        acceptedFiles: ".csv",
        maxFiles: 1,
        previewTemplate: document.getElementById("file-preview-template").innerHTML,
        clickable: true,
        dictDefaultMessage: "",
        init: function () {
          const message = that.dropzoneTarget.querySelector(".dz-message");
          if (message) {
            message.querySelector('button').textContent = "Drag & drop a CSV file here or click to upload"
          }

          this.on("addedfile", (file) => that.handleFileAdded(file));
          this.on("removedfile", () => {
            that.resetTable(); // Reset table on file removal
          });
        }
      });
    } catch (error) {
      console.error("Error in initializeDropzone:", error);
    }
  }

  simulateProcessing(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async handleFileAdded(file) {
    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      alert("Only CSV files are allowed.");
      this.dropzone.removeFile(file);
      return;
    }

    const progressBar = file.previewElement.querySelector("[data-dz-uploadprogress]");
    const progressPercent = file.previewElement.querySelector("[data-dz-uploadprogresspercent]");
    progressBar.style.width = "0%"; // Reset progress bar
    progressPercent.textContent = "0%"; // Reset progress bar

    // Read the file as text
    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvContent = e.target.result;
      const rows = csvContent.trim().split("\n").slice(1).map((row) => row.split(","));
      const lineCount = rows.length;
      this.inputTarget.value = csvContent.trim().split("\n").slice(1).join("\n");

      // Update progress as each row is processed
      const totalRows = rows.length;
      for (let i = 1; i <= totalRows; i++) { // Start from 1 to skip header
        // Simulate processing delay for demonstration
        await this.simulateProcessing(10); // 10ms delay for each row

        // Update progress percentage
        const progress = ((i / totalRows) * 100);
        progressBar.style.width = `${progress}%`;
        progressPercent.textContent = `${progress.toFixed(2)}%`;
      }

      if (lineCount > LIMIT_UPLOAD) {
        this.dropzone.removeFile(file);
      } else {
        const parsedData = rows.map((row) => this.parseRow(row));
        this.renderTable(parsedData);
      }
    };

    reader.readAsText(file);
  }

  resetTable() {
    // Clear the table body
    this.tableBodyTarget.innerHTML = "";
    this.totalRows = 0;
    this.inputTarget.value = '';
    this.previewTarget.classList.add('hidden');
  }

  parseRow(row) {
    try {
      const [Email = "", FirstName = "", LastName = "", Company = ""] = row.map((cell) => cell.trim());
      return {Email, FirstName, LastName, Company};
    } catch (error) {
      console.error("Error in parseRow:", error);
    }
  }

  renderTable(dataRows) {
    try {
      this.totalRows = dataRows.length;

      if (dataRows.length > 0) {
        this.previewTarget.classList.remove('hidden')
      } else {
        this.previewTarget.classList.add('hidden')
      }

      this.tableBodyTarget.innerHTML = "";

      dataRows.forEach(({Email, FirstName, LastName, Company }) => {
        const tr = document.createElement("tr");

        tr.innerHTML = `
          <td class="p-2 border-b text-gray-600">${Email}</td>
          <td class="p-2 border-b text-gray-600">${FirstName}</td>
          <td class="p-2 border-b text-gray-600">${LastName}</td>
          <td class="p-2 border-b text-gray-600">${Company}</td>
        `;
        this.tableBodyTarget.appendChild(tr);
      });
    } catch (error) {
      console.error("Error in renderTable:", error);
    }
  }

  initListModal() {
    try {
      const that = this;
      const $targetEl = document.getElementById("addListModal");

      const options = {
        placement: "bottom-right",
        backdrop: "dynamic",
        backdropClasses:
          "add-list-modal-backdrop bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
        closable: true,
        onHide: () => {
          document.querySelectorAll(".add-list-modal-backdrop").forEach((element) => {
            element.remove();
          });
          if (this.modalTarget) {
            this.modalTarget.classList.add('z-50');
            this.modalTarget.classList.remove('z-30');
          }
        },
        onShow: () => {
          if (this.modalTarget) {
            this.modalTarget.classList.remove('z-50');
            this.modalTarget.classList.add('z-30');
          }
        }
      };

      this.listModal = new Modal($targetEl, options, { id: "addListModal", override: true });
    } catch (error) {
      console.error("Error in initModal:", error);
    }
  }

  openList() {
    try {
      this.listModal.show()
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  closeList(event) {
    try {
      this.listModal.hide()
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  initUploadModal() {
    // set the modal menu element
    const modalId = `upload-modal`;
    const $targetEl = document.getElementById(modalId);

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: modalId,
      override: true
    };

    this.uploadModal = new Modal($targetEl, options, instanceOptions);
  }

  openUploadModal() {
    this.uploadModal.show()
  }

  closeUploadModal() {
    this.uploadModal.hide()
  }

  uploadEnd(event) {
    // this.uploadModal.hide()
  }

  toggle(e) {
    super.toggle(e)
    this.updateInputHidden();
    this.updateStateButtonAction();
  }

  refresh() {
    super.refresh()
    this.updateInputHidden();
    this.updateStateButtonAction();
  }

  updateStateButtonAction() {
    if (this.hasButtonActionTarget) {
      this.buttonActionTargets.forEach(e => {
        e.disabled = this.checked.length == 0
        if (e.value == "remove_campaign_emails") {
          e.dataset.turboConfirm = `Are you sure you want to delete ${this.checked.length} recepients address?`
        }
      })
    }
  }

  updateInputHidden() {
    const selectedForm = document.getElementById('selectedForm')
    if (selectedForm) {
      if (this.checked.length > 0) {
        this.checked.forEach(e => this.handleCheckboxChange(e));
      } else {
        selectedForm.querySelectorAll('.selected_ids').forEach(e => e.remove());
      }
    }
  }

  verifyHandle(e) {
    if (this.checked.length > 0) {
      this.checked.forEach(e => this.addLoadingIcon(e));
    } else {
      this.checkboxTargets.forEach(e => this.addLoadingIcon(e));
    }
  }

  addLoadingIcon(e) {
    const element = document.getElementById(`campaign_email_status_${e.value}`)
    if (element) {
      element.innerHTML = `<svg aria-hidden="true" role="status" class="inline w-4 h-4 animate-spin text-gray-200 dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                </svg>`;
    }
  }

  handleCheckboxChange(checkbox) {
    const form = document.getElementById('selectedForm');

    // Check if the checkbox is checked
    if (checkbox.checked) {
      // Create a hidden input
      const hiddenInput = document.createElement('input');
      hiddenInput.type = 'hidden';
      hiddenInput.name = checkbox.name;  // Unique name for the hidden field
      hiddenInput.value = checkbox.value;
      hiddenInput.id = checkbox.value + '_hidden'; // Unique ID
      hiddenInput.classList.add('selected_ids')

      // Append the hidden input to the form
      form.appendChild(hiddenInput);
    } else {
      // Remove the hidden input if the checkbox is unchecked
      const hiddenInput = document.getElementById(checkbox.value + '_hidden');
      if (hiddenInput) {
        form.removeChild(hiddenInput);
      }
    }
  }

  endAction() {
    if (this.searchFormTarget) {
      this.searchFormTarget.requestSubmit();
    }
  }
}
